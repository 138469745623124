import React from 'react';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import { RegistrationForm } from './patterns/RegistrationForm/RegistrationForm';
import DecisionStep from './patterns/DecisionStep/DecisionStep';
import ZipAddressStepWithSearch from './patterns/ZipAddressStep/ZipAddressStepWithSearch';
import ContactDataStep from './patterns/ContactDataStep/ContactDataStep';
import BuroValidationOTP from './patterns/BuroValidationOTP/BuroValidationOTP';
import CurpAutofillDecision from './patterns/PersonalInfoStep/AutofillExperiment/CurpAutofillDecision';
import CurpFeedback from './patterns/PersonalInfoStep/FeedbackExperiment/CurpFeedback';
import CurpAutofillBtn from './patterns/PersonalInfoStep/AutofillExperiment/AutofillBtn/CurpAutofillBtn';

const AUTOFILL_EXPERIMENT_ID = '343114';
const AUTOFILL_BUTTON_5_EXPERIMENT_VARIANT = '44438';
const AUTOFILL_BUTTON_10_EXPERIMENT_VARIANT = '44439';

const RegistrationFormWithCURP = () => {
  const activeAutofillVariant = useFigPiiExperiment(AUTOFILL_EXPERIMENT_ID);

  // CURP Autofill Button
  if (activeAutofillVariant === AUTOFILL_BUTTON_5_EXPERIMENT_VARIANT
      || activeAutofillVariant === AUTOFILL_BUTTON_10_EXPERIMENT_VARIANT) {
    return (
      <RegistrationForm>
        <ContactDataStep />
        <CurpAutofillBtn />
        <CurpAutofillDecision />
        <ZipAddressStepWithSearch shouldTriggerOTP />
        <BuroValidationOTP />
        <DecisionStep />
      </RegistrationForm>
    );
  }

  // Original
  return (
    <RegistrationForm>
      <ContactDataStep />
      <CurpFeedback />
      <ZipAddressStepWithSearch shouldTriggerOTP />
      <BuroValidationOTP />
      <DecisionStep />
    </RegistrationForm>
  );
};

export default RegistrationFormWithCURP;
