import { getConfig } from '@nubank/www-latam-commons/config';

const config = getConfig();

export const get = () => new Promise(((resolve, reject) => {
  // eslint-disable-next-line no-undef
  try {
    const reCaptchaV3 = window.grecaptcha;
    return reCaptchaV3.enterprise.ready(() => reCaptchaV3.enterprise.execute(config.recaptchaSiteKey, { action: 'submit' }).then(resolve));
  } catch (error) {
    return reject(error);
  }
}));
