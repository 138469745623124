import React, { useState, useEffect } from 'react';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import Box from '@nubank/nuds-web/components/Box/Box';
import { createPortal } from 'react-dom';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';
import WebsiteProvider from '@nubank/www-latam-commons/components/WebsiteProvider/WebsiteProvider';
import RouterLink from 'components/RouterLink/RouterLink';
import useWindowSize from '@nubank/www-latam-commons/utils/hooks/useWindowSize';

import { useSiteContext } from '../../components/SiteContext/SiteContext';
import WebsiteSEO from '../../components/WebsiteSEO/WebsiteSEO';
import esMXLocalMessages from '../../locale/es-MX.json';
import HeaderXP from '../../components/HeaderXP/HeaderXP';
import Footer from '../../components/FooterXP/Footer';
import { images } from '../../utils/seo';
import RegistrationFormWithCURP from '../../screens/Registration/RegistrationFormWithCURP';
import MenuWrapper from '../../components/HeaderXP/MenuWrapper/MenuWrapper';
import ApplyPopup from '../../components/ApplyPopUp/ApplyPopUp';

export const HEADER_TYPE_WITH_MENU_AND_APPLICATION = 'WITH_MENU_AND_APPLICATION';
export const HEADER_TYPE_NO_MENU_NO_APPLICATION = 'NO_MENU_NO_APPLICATION';
export const HEADER_TYPE_NONE = 'HEADER_TYPE_NONE';

export const FOOTER_TYPE_FULL = 'FOOTER_TYPE_FULL';
export const FOOTER_TYPE_NO_NAVIGATION = 'FOOTER_TYPE_NO_NAVIGATION';
export const FOOTER_TYPE_NONE = 'FOOTER_TYPE_NONE';

// Pop Up Experiment
const POP_UP_EXPERIMENT_ID = '343892';
const POP_UP_1_MIN_EXPERIMENT_VARIANT = '45723';
const POP_UP_2_MIN_EXPERIMENT_VARIANT = '45942';

const customPolyfills = [
  'Intl',
];

const websitePage = (WrappedComponent, options = {}) => {
  const hocOptions = {
    header: HEADER_TYPE_WITH_MENU_AND_APPLICATION,
    footer: FOOTER_TYPE_FULL,
    routeKey: 'HOME',
    customHeaderCTA: undefined,
    seoComponent: WebsiteSEO,
    allowApplicationForm: true,
    isWhiteColorHero: true,
    buttonLabel: 'HOME.MULTIPRODUCT.HERO.CTA.BTN',
    btnVisibleScrolling: undefined,
    customSEOImages: false,
    ...options,
  };

  const WebsitePageComponent = props => {
    // eslint-disable-next-line react/prop-types
    const { cmsMessages } = props;
    const {
      seoComponent: SEOComponent,
      routeKey,
      allowApplicationForm,
      header,
      footer,
    } = hocOptions;

    const {
      isRegistrationFormOpen,
      closeRegistrationForm,
      displayPopUpContext,
      updateDisplayPopUpContext,
    } = useSiteContext();

    const hideCTAButton = !allowApplicationForm || [
      HEADER_TYPE_NO_MENU_NO_APPLICATION,
    ].includes(header);

    const hideFooterNavigation = footer === FOOTER_TYPE_NO_NAVIGATION;
    const showHeader = header !== HEADER_TYPE_NONE;
    const showFooter = footer !== FOOTER_TYPE_NONE;

    const SEOimages = hocOptions.customSEOImages?.length >= 1 ? hocOptions.customSEOImages : images;

    // Pop Up Experiment
    // The pop up will be displayed after 1 or 2 minutes
    // And after the user scrolls down 3 times the height of the screen
    const { height } = useWindowSize();
    const activePopUpVariant = useFigPiiExperiment(POP_UP_EXPERIMENT_ID);
    const popUpXpActive = activePopUpVariant === POP_UP_1_MIN_EXPERIMENT_VARIANT
    || activePopUpVariant === POP_UP_2_MIN_EXPERIMENT_VARIANT;
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    let showPopUp = true;

    const openPopUpTimer = () => {
      const time = activePopUpVariant === POP_UP_1_MIN_EXPERIMENT_VARIANT ? 100000 : 200000;
      // Check if we already displayed the pop up & if the xp is active
      setTimeout(() => {
        if (showPopUp && displayPopUpContext && popUpXpActive) {
          showPopUp = false;
          setIsPopUpOpen(true);
        }
      }, time);
    };

    const handleScroll = () => {
      const yPosition = window.scrollY;
      const hidePopUp = yPosition <= (height * 3);
      if (showPopUp && displayPopUpContext) {
        if (popUpXpActive && !hidePopUp) {
          showPopUp = false;
          setIsPopUpOpen(true);
        }
      }
    };

    useEffect(() => {
      openPopUpTimer();

      handleScroll();
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    useEffect(() => {
      updateDisplayPopUpContext(showPopUp);
    }, [showPopUp]);

    return (
      <WebsiteProvider
        locale={process.env.NEXT_PUBLIC_WWW_LOCALE}
        messages={{ ...esMXLocalMessages, cms: cmsMessages }}
        routerLinkComponent={RouterLink}
        customPolyfillFeatures={customPolyfills}
        enablePolyfill={false}
      >
        <SEOComponent routeKey={routeKey} images={SEOimages} />

        {showHeader && (
        <HeaderXP>
          <MenuWrapper
            hideCTAButton={hideCTAButton}
            customHeaderCTA={hocOptions.customHeaderCTA}
            isWhiteColorHero={hocOptions.isWhiteColorHero}
            buttonLabel={hocOptions.buttonLabel}
            btnVisibleScrolling={hocOptions.btnVisibleScrolling}
          />
        </HeaderXP>
        )}

        <Box
          id="main-content"
          display="flex"
          flexDirection="column"
          flex="1"
        >
          {
            allowApplicationForm && (
            <Drawer
              id="prospect-registration-form"
              onClose={closeRegistrationForm}
              open={isRegistrationFormOpen}
            >
              {({ DrawerContent }) => (
                <DrawerContent>
                  <RegistrationFormWithCURP />
                </DrawerContent>
              )}
            </Drawer>
            )
          }
          <WrappedComponent {...props} />
        </Box>

        {isPopUpOpen && typeof document !== 'undefined' && createPortal(
          <ApplyPopup
            setIsPopUpOpen={setIsPopUpOpen}
            isPopUpOpen={isPopUpOpen}
          />, document.getElementById('modal-root'),
        )}

        {showFooter && <Footer hideFooterNavigation={hideFooterNavigation} />}

      </WebsiteProvider>

    );
  };

  if (WrappedComponent.getInitialProps) {
    WebsitePageComponent.getInitialProps = async cyx => {
      const pageProps = await WrappedComponent.getInitialProps(cyx);

      return {
        ...pageProps,
      };
    };
  }

  return WebsitePageComponent;
};

export default websitePage;
