import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Form from '@nubank/nuds-web/components/Form/Form';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Snackbar from '@nubank/nuds-web/components/Snackbar/Snackbar';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import { validateCURP } from '../../../../../../domains/candidate/candidateValidation';
import { useSiteContext } from '../../../../../../components/SiteContext/SiteContext';
import { useRegistrationFormContext } from '../../../RegistrationForm/RegistrationForm';
import Spinner from '../../../../../../components/Spinner/Spinner';
import {
  Counter, ErrorMessage, FeedbackBox, StyledTextFieldBox, LoaderBox,
} from '../../components/StyleFeedback';
import { isValidPersonCURP } from '../../../../../../utils/form/validationUtils';
import { get as getCaptcha } from '../../../../../../utils/recaptcha';
import { curpAutofillEvent } from '../../../../tracking';

const StyledSubtitle = styled(Typography)`
  padding-right: 10px;

  span {
    color: #820AD1;
    font-weight: bold;
  }
`;

const StyledSection = styled(Box)`
  button {
    min-width: 64px;
    height: 64px;
  }

  span {
    color: ${props => (props.loadingBtn ? '#9b999c' : 'auto')};
    margin-bottom: 4px;
  }

  span > svg {
    margin-bottom: -6px;
    margin-left: 15px;
  }
`;

const StyledButton = styled(Button)`
  min-width: 64px;
  height: 64px;
`;

const TypographyBox = styled(Typography)`
  span {
    font-weight: bold;
  }
`;

const AUTOFILL_EXPERIMENT_ID = '343114';
const AUTOFILL_BUTTON_10_EXPERIMENT_VARIANT = '44439';

const RECAPTCHA_EXPERIMENT_ID = '343704';
const RECAPTCHA_EXPERIMENT_VARIANT = '45402';

function CurpAutofillBtn() {
  // Timeout for the response from RENAPO xp
  const activeAutofillVariant = useFigPiiExperiment(AUTOFILL_EXPERIMENT_ID);
  const timeout = activeAutofillVariant === AUTOFILL_BUTTON_10_EXPERIMENT_VARIANT ? 10 : 5;
  // ReCaptcha xp
  const activeReCaptchaVariant = useFigPiiExperiment(RECAPTCHA_EXPERIMENT_ID);
  const activeCaptcha = activeReCaptchaVariant === RECAPTCHA_EXPERIMENT_VARIANT;

  // Forbidden by reCaptcha message
  const formErrorMsg = useState('Ocurrió un error, por favor intenta de nuevo, si el problema persiste, por favor contacta a soporte');
  const [displayFormErrorMsg, setDisplayFormErrorMsg] = useState(false);

  const curpInputRef = useRef();
  const {
    discoveryUrlsList,
  } = useSiteContext();
  const [isLoading, setIsLoading] = useState(false);
  const [curpInput, setCurpInput] = useState('');
  const {
    setCurpValidationResponse, setNoValidation, setSkipCurpValidation,
    validatedCurps, getValidatedCurps, setProspectGender,
  } = useRegistrationFormContext();
  const [invalidMessageCURP, setInvalidMessageCURP] = useState(false);
  const [invalidFormat, setInvalidFormat] = useState(false);
  const [firstTimeValidation, setFirstTimeValidation] = useState(true);
  const [fillPersonalInfo, setFillPersonalInfo] = useState(false);

  const [disableCURPBtn, setDisableCURPBtn] = useState(true);

  // CURP Input Validation
  const changeCurpInput = text => {
    if (isValidPersonCURP(text)) {
      setDisableCURPBtn(false);
      setInvalidFormat(false);
    } else {
      setDisableCURPBtn(true);
      setInvalidFormat(true);
    }
  };

  // Trigger the validation of the CURP each time the user update the input
  useEffect(() => {
    changeCurpInput(curpInput);
  }, [curpInput]);

  // Move to next screen
  const nextScreen = (nextStep, setSubmitting) => {
    setIsLoading(false);
    setSubmitting(false);
    nextStep();
  };

  // Check if the CURP is valid
  const responseValidation = (apiResponse, nextStep, setSubmitting) => {
    // Forbidden by reCaptcha
    if (apiResponse?.response?.status && apiResponse.response.status === 403) {
      setDisplayFormErrorMsg(true);
      setFirstTimeValidation(true);
      setIsLoading(false);
      return;
    }

    setCurpValidationResponse(apiResponse);

    if (apiResponse && apiResponse.valid) {
      setProspectGender(apiResponse.gender === 'non_binary' ? 'non-binary' : apiResponse.gender); // Convert 'non_binary' to 'non-binary', or keep male (H) /female (M)
      curpAutofillEvent('bnt curp valid');
      nextScreen(nextStep, setSubmitting);
    } else {
      // Invalid CURP
      curpAutofillEvent('btn curp invalid');
      setInvalidMessageCURP(true);
      setIsLoading(false);
    }
  };

  const invalidOrTimedOut = (nextStep, setSubmitting) => {
    curpAutofillEvent('btn timeout');
    setCurpValidationResponse({});
    setSkipCurpValidation(true);
    nextScreen(nextStep, setSubmitting);
  };

  // Call to validate the CURP
  let apiResponse = false;
  async function curpValidationCall(values, captcha) {
    apiResponse = false;
    const captchaKey = activeCaptcha ? captcha : null;
    const response = await validateCURP(values.curp, discoveryUrlsList, captchaKey, activeCaptcha);
    apiResponse = response;
    return apiResponse;
  }

  // Timer to wait only 5s for the response from RENAPO
  const timer = (values, nextStep, setSubmitting, captcha) => {
    setFirstTimeValidation(false);
    curpValidationCall(values, captcha);
    let count = 0;
    const timeInterval = setInterval(() => {
      count += 0.5;

      // We have a response on time
      if (count < timeout && apiResponse) {
        responseValidation(apiResponse, nextStep, setSubmitting, count);
        clearInterval(timeInterval);
      }

      // Timeout
      if (count >= timeout) {
        invalidOrTimedOut(nextStep, setSubmitting, count);
        clearInterval(timeInterval);
      }
    }, 500);
    timeInterval();
  };

  // Triggered when the user clicks the btn to continue
  const handleSubmit = async ({
    nextStep, setSubmitting, values,
  }) => {
    setNoValidation(false);

    if (fillPersonalInfo) {
      setNoValidation(true);
      /// Reset prospect gender so we use the one selected from radiobuttons
      setProspectGender('');
      curpAutofillEvent('btn genera tu curp');
      nextScreen(nextStep, setSubmitting);
      return;
    }

    setIsLoading(true);
    // Check if the CURP was already validated
    if (!firstTimeValidation && validatedCurps.includes(curpInput)) {
      setSkipCurpValidation(true);
      nextScreen(nextStep, setSubmitting);
    } else {
      getValidatedCurps(curpInput);
      const captcha = await getCaptcha();
      timer(values, nextStep, setSubmitting, captcha);
    }
  };

  useEffect(() => {
    curpInputRef.current?.focus();
    setInvalidFormat(false);
    curpAutofillEvent('btn displayed');
  }, []);

  return (
    <Form.Step
      initialValues={{
        curp: '',
      }}
      initialTouched={{ countryOfBirth: true }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {() => (
        <Box
          overflowY="auto"
          paddingTop={{ xs: '24px', lg: '40px' }}
          paddingBottom="4x"
          paddingHorizontal={{ xs: '24px', lg: '40px' }}
          position="relative"
          display="flex"
          flexDirection="column"
          minHeight="100%"
        >

          <Typography
            variant="heading3"
            marginBottom="4x"
          >
            Con tu CURP ingresas tus datos personales como si fuera magia
          </Typography>

          <StyledSubtitle variant="subtitle1" marginBottom="8x">
            O si lo prefieres, puedes escribir tus datos manualmente.
          </StyledSubtitle>

          <StyledTextFieldBox>
            { isLoading
          && (
          <LoaderBox
            position="relative"
            float="right"
            top="33px"
          >
            <Spinner color="#820AD1" />
          </LoaderBox>
          )}
            <TextField
              id="curp"
              name="curp"
              ref={curpInputRef}
              maxLength={18}
              label="Escribe tu CURP"
              onInput={e => setCurpInput(e.target.value)}
            />
          </StyledTextFieldBox>
          <FeedbackBox adjustMargin={isLoading}>
            <ErrorMessage>
              {invalidMessageCURP && isValidPersonCURP(curpInput) ? 'Revisa tu CURP o llena tus datos' : null}
              {invalidFormat ? 'Confirma tu CURP antes de continuar' : null}
            </ErrorMessage>
            <Counter input={curpInput.length} length={18}>
              {`${18 - curpInput.length}/18`}
            </Counter>
          </FeedbackBox>
          <Box
            backgroundColor="#F6ECFF"
            borderRadius="25px"
            marginTop="4x"
            marginBottom="2x"
            paddingHorizontal={{ xs: '6x' }}
            paddingVertical={{ xs: '4x' }}
            height="100%"
            display="flex"
            flexDirection="column"
          >

            <TypographyBox>
              <span>Encuentra tu CURP en tu INE 🔎  </span>
            </TypographyBox>
            <Link
              variant="paragraph1"
              href="https://www.gob.mx/curp/"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              También puedes consultar el sitio oficial
            </Link>

          </Box>

          {/* ENDS FIELDS FROM PERSONAL INFO 2 */}

          <StyledSection
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop="auto"
          >
            <Button
              id="autofill-submit-btn"
              variant="contained"
              styleVariant="primary"
              extended
              type="submit"
              disabled={disableCURPBtn || isLoading}
            >
              {isLoading ? 'Buscando tu información' : 'Continuar con CURP'}
              { isLoading ? <Spinner /> : null }
            </Button>
          </StyledSection>

          <StyledButton
            variant="contained"
            styleVariant="white"
            extended
            type="submit"
            onClick={() => setFillPersonalInfo(true)}
            disabled={isLoading}
          >
            Escribir datos manualmente
          </StyledButton>

          {/* Error message */}
          <Snackbar
            visible={displayFormErrorMsg}
            onActionClick={() => setDisplayFormErrorMsg(false)}
            actionText="Cerrar"
          >
            {formErrorMsg}
          </Snackbar>
        </Box>
      )}
    </Form.Step>
  );
}

export default CurpAutofillBtn;

